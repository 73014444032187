.animated-text span {
    animation-name: color-change;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    color: #E3E3DD;
}

@keyframes color-change {
    0%, 100% { color: #0F0F0F; }
}
